export default {
  heading: 'Playfair Display, serif',
  subheading: 'Raleway, sans-serif',
  body: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Tangerine, cursive',
  display2: 'Tangerine',
  display3: 'Tangerine',
  googleFonts: [
    'Charm:100,200,300,400,500,600,700,800,900',
    'Playfair Display:100,200,300,400,500,600,700,800,900',
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Questrial',
    'Raleway:100,200,300,400,500,600,700,800,900',
    'Italiana',
    'Tangerine'
  ]
  // customFamilies: [''],
  // customUrls: [''],
}

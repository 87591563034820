export default {
  primary: {
    backgroundColor: 'black',
    color: 'light',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: ['0.9rem', '', '1.2rem'],
    padding: '1rem 1.5rem',
    ':hover': {
      backgroundColor: 'secondary',
      color: 'text'
    }
  },
  secondary: {
    backgroundColor: 'secondary',
    color: 'text',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: ['0.9rem', '', '1.2rem'],
    padding: '1rem 1.5rem',
    ':hover': {
      backgroundColor: 'tertiary',
      color: 'white'
    }
  }
}

export default {
  text: '#6e6a6a',
  primary: '#202020',
  secondary: '#98997a',
  tertiary: '#111',
  background: '#f7f6ef',
  backgroundSecondary: '#fff',
  light: '#FFF',
  dark: '#1117'
}
